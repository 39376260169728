import React, { useState, useCallback, useEffect } from "react";
import loadable from "@loadable/component";

const Map = loadable(() =>
  import(/* webpackPreload: true */ "../../components/Map")
);

const Home = loadable(() =>
  import(/* webpackPreload: true */ "../../components/home.js")
);
const MainVideo = loadable(() =>
  import(/* webpackPreload: true */ "../../components/main_video.js")
);

const ExhibitionTable = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/ExhibitionTable")
);

const Discourse = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/discourse")
);

const Declaration = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/declaration")
);

const Project_a = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a")
);
const Project_a_gift = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a_gift")
);
const Project_a_muse = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a_muse")
);
const Project_a_muse_1 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a_muse_1")
);
const Project_a_muse_2 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a_muse_2")
);
const Project_a_muse_3 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a_muse_3")
);
const Project_a_muse_4 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a_muse_4")
);
const Project_a_muse_5 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a_muse_5")
);
const Project_a_muse_6 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_a_muse_6")
);

const Project_b = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_b")
);
const Project_b_campfire = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_b_campfire")
);
const Project_b_article = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_b_article")
);
const Project_b_article_2 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_b_article_2")
);

const Project_c = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_c")
);
const Project_c_1 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_c_1")
);
const Project_c_2 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_c_2")
);
const Project_c_3 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_c_3")
);
const Project_c_4 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_c_4")
);
const Project_c_4_1 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_c_4_1")
);
const Project_c_4_2 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_c_4_2")
);
const Project_c_4_3 = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_c_4_3")
);

const Project_d = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/project_d")
);
// const Project_d_plant = loadable(() =>
//   import(/* webpackPrefetch: true */ "../../components/project_d_plant")
// );
// const Project_d_test = loadable(() =>
//   import(/* webpackPrefetch: true */ "../../pages/project_d")
// );

const Other = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/other")
);

const Blog = loadable(() =>
  import(/* webpackPrefetch: true */ "../../components/blog")
);
const Post_1_1 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_1") );
const Post_1_2 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_2") );
const Post_1_3 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_3") );
const Post_1_4 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_4") );
const Post_1_5 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_5") );
const Post_1_6 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_6") );
const Post_1_7 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_7") );
const Post_1_8 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_8") );
const Post_1_9 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_9") );
const Post_1_10 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_10") );
const Post_1_11 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_11") );
const Post_1_12 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_1_12") );
const Post_2_1 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_1") );
const Post_2_2 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_2") );
const Post_2_3 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_3") );
const Post_2_4 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_4") );
const Post_2_5 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_5") );
const Post_2_6 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_6") );
const Post_2_7 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_7") );
const Post_2_8 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_8") );
const Post_2_9 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_9") );
const Post_2_10 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_2_10") );
const Post_3_1 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_3_1") );
const Post_3_2 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_3_2") );
const Post_3_3 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_3_3") );
const Post_4 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_4") );
const Post_5 = loadable(() => import(/* webpackPrefetch: true */ "../../components/Blog/post_5") );

const Campfire = loadable(() =>
  import(
    /* webpackPrefetch: true */ "../../components/Curations/FANG_YAN_XIANG/Campfire"
  )
);
const PlantsLight = loadable(() =>
  import(
    /* webpackPrefetch: true */ "../../components/Curations/LAI_YING_RU/PlantsLight"
  )
);

export default [
  { path: "/", Component: Home },
  { path: "/main_video", Component: MainVideo },
  { path: "/discourse", Component: Discourse },
  { path: "/declaration", Component: Declaration },
  { path: "/project_a", Component: Project_a },
  { path: "/project_a_gift", Component: Project_a_gift },
  { path: "/project_a_muse", Component: Project_a_muse },
  { path: "/project_a_muse_1", Component: Project_a_muse_1 },
  { path: "/project_a_muse_2", Component: Project_a_muse_2 },
  { path: "/project_a_muse_3", Component: Project_a_muse_3 },
  { path: "/project_a_muse_4", Component: Project_a_muse_4 },
  { path: "/project_a_muse_5", Component: Project_a_muse_5 },
  { path: "/project_a_muse_6", Component: Project_a_muse_6 },
  { path: "/project_b", Component: Project_b },
  { path: "/campfires", Component: Campfire },
  // { path: "/project_b_campfire", Component: Project_b_campfire },
  { path: "/project_b_article", Component: Project_b_article },
  { path: "/project_b_article_2", Component: Project_b_article_2 },
  { path: "/project_c", Component: Project_c },
  { path: "/project_c_1", Component: Project_c_1 },
  { path: "/project_c_2", Component: Project_c_2 },
  { path: "/project_c_3", Component: Project_c_3 },
  { path: "/project_c_4", Component: Project_c_4 },
  { path: "/project_c_4_1", Component: Project_c_4_1 },
  { path: "/project_c_4_2", Component: Project_c_4_2 },
  { path: "/project_c_4_3", Component: Project_c_4_3 },
  
  { path: "/project_d", Component: Project_d },
  { path: "/plants", Component: PlantsLight },
  // { path: "/project_d_plant", Component: Project_d_plant },
  // { path: "/project_d_test", Component: Project_d_test },

  { path: "/other", Component: Other },
  { path: "/blog", Component: Blog },
  { path: "/blog/post_1_1", Component: Post_1_1 },
  { path: "/blog/post_1_2", Component: Post_1_2 },
  { path: "/blog/post_1_3", Component: Post_1_3 },
  { path: "/blog/post_1_4", Component: Post_1_4 },
  { path: "/blog/post_1_5", Component: Post_1_5 },
  { path: "/blog/post_1_6", Component: Post_1_6 },
  { path: "/blog/post_1_7", Component: Post_1_7 },
  { path: "/blog/post_1_8", Component: Post_1_8 },
  { path: "/blog/post_1_9", Component: Post_1_9 },
  { path: "/blog/post_1_10", Component: Post_1_10 },
  { path: "/blog/post_1_11", Component: Post_1_11 },
  { path: "/blog/post_1_12", Component: Post_1_12 },
  { path: "/blog/post_2_1", Component: Post_2_1 },
  { path: "/blog/post_2_2", Component: Post_2_2 },
  { path: "/blog/post_2_3", Component: Post_2_3 },
  { path: "/blog/post_2_4", Component: Post_2_4 },
  { path: "/blog/post_2_5", Component: Post_2_5 },
  { path: "/blog/post_2_6", Component: Post_2_6 },
  { path: "/blog/post_2_7", Component: Post_2_7 },
  { path: "/blog/post_2_8", Component: Post_2_8 },
  { path: "/blog/post_2_9", Component: Post_2_9 },
  { path: "/blog/post_2_10", Component: Post_2_10 },
  { path: "/blog/post_3_1", Component: Post_3_1 },
  { path: "/blog/post_3_2", Component: Post_3_2 },
  { path: "/blog/post_3_3", Component: Post_3_3 },
  { path: "/blog/post_4", Component: Post_4 },
  { path: "/blog/post_5", Component: Post_5 },

  // { path: "/exhibition", Component: ExhibitionTable },
];
