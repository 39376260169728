import React, {
  Suspense,
  // useRef,
  // useState,
  // useCallback,
  useEffect,
} from "react";
import PropTypes, { arrayOf } from "prop-types";
import { Match } from "@reach/router";
import { Transition } from "react-transition-group";
import { gsap, Expo } from "gsap";
import InnerHeight from 'ios-inner-height'
import { isMobile } from "react-device-detect"
import window from 'global'

import {
  START_TRANSITION,
  END_TRANSITION,
  ContextStore,
} from "../../hooks/useContextSPATransitionLock.js";

import sty from "./TransitionMatchHOC.module.scss";


const TransitionMatchHOC = ({ children, path }) => {

  const { dispatchTransitionType } = React.useContext(ContextStore);

  // var Wrap_InnerHeight = {
  //   width: window.innerWidth + 'px',
  //   height: InnerHeight() + 'px',
  //   overflowX: 'hidden'
  // };

  const Wrap_InnerHeight = {
    width: window.innerWidth + 'px',
    height: window.innerHeight + 'px',
    overflowX: 'hidden'
  };
  

  

  // var wrap = document.querySelector('#wrap');
  // console.log(InnerHeight);
  // document.querySelector('#wrap').style.height = InnerHeight() + 'px';

  return (
    <Match path={path}>
      {({ match }) => {
        /* console.log(match); */

        return (
          <Transition
            in={match !== null}
            // timeout={1000}
            mountOnEnter
            unmountOnExit
            addEndListener={(node, done) => {
              if (match !== null) {
                dispatchTransitionType({ type: START_TRANSITION });
                gsap.from(node, {
                  zIndex: 1,
                  duration: 2,
                  maskPosition: "100% 0",
                  ease: "steps(33)",
                  // height: window.innerHeight + 'px',
                  onComplete: () => {
                    dispatchTransitionType({ type: END_TRANSITION });
                    // node.style.height = 'auto';
                    done();
                  },
                });
              } else {
                gsap.set(node, {
                  clearProps: "zIndex",
                });
                gsap.to(node, {
                  opacity: 0,
                  duration: .4,
                  // height: window.innerHeight + 'px',
                  ease: Expo.easeOut,
                  // onComplete: done,
                  onComplete: () => {
                    dispatchTransitionType({ type: END_TRANSITION });
                    // node.style.height = 'auto';
                    
                    // wrap.style.height = 'initial';
                    // wrap.style.overflowX = 'initial';
                    done();
                  },                  
                });
              }
            }}
          >
            <div id="wrap" className={sty.TransitionMatchHOC} style={isMobile ? Wrap_InnerHeight : null}>{children}</div>
          </Transition>
        );
      }}
    </Match>
  );
};

TransitionMatchHOC.propTypes = {
  children: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default TransitionMatchHOC;
