export default [
  {
    pathname: "/testComponent/",
    bgColor: "rgba(238, 196, 196, 1)",
    strokeColor: "rgba(194, 209, 230, 1)",
    interactionColorConfig: [
      {
        type: "hover",
        domID: "test-dom",
        color: "blue",
      },
      {
        type: "click",
        domID: "test-dom",
      },
    ],
  },
  {
    // default must put in the last because I use the .includes method
    pathname: "/discourse",
    bgColor: "#EADF5A",
    strokeColor: "#F1E765",
  },
  {
    pathname: "/declaration",
    bgColor: "#EADF5A",
    strokeColor: "#F1E765",
  },
  {
    pathname: "/main_video",
    bgColor: "#000",
    strokeColor: "#000",
  },
  {
    pathname: "/project_a",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_a_gift",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_a_muse",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_a_muse_1",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_a_muse_2",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_a_muse_3",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_a_muse_4",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_a_muse_5",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_a_muse_6",
    bgColor: "#F2BBBB",
    strokeColor: "#F2BBBB",
  },
  {
    pathname: "/project_b",
    bgColor: "#F2BBBB",
    strokeColor: "#DDD",
  },
  {
    pathname: "/project_b_campfire",
    bgColor: "#F2BBBB",
    strokeColor: "#DDD",
  },
  {
    pathname: "/project_c",
    bgColor: "#CABCD6",
    strokeColor: "#CABCD6",
  },
  {
    pathname: "/project_d",
    bgColor: "#BAD5A9",
    strokeColor: "#BAD5A9",
  },
  {
    pathname: "/other",
    bgColor: "#DDD",
    strokeColor: "#F8F2E4",
  },
  {
    pathname: "/blog",
    bgColor: "#F8F2E4",
    strokeColor: "#F8F2E4",
  },

  {
    // default must put in the last because I use the .includes method
    pathname: "/",
    bgColor: "#F8F2E4",
    strokeColor: "#F8F2E4",
    interactionColorConfig: [
      {
        type: "hover",
        domID: "link_taipei",
        color: "#F2BBBB",
      },
      {
        type: "click",
        domID: "link_taipei",
        color: "#F2BBBB",
      },

      {
        type: "hover",
        domID: "link_discourse",
        color: "#EADF5A",
      },
      {
        type: "click",
        domID: "link_discourse",
        color: "#EADF5A",
      },

      {
        type: "hover",
        domID: "link_declaration",
        color: "#EADF5A",
      },
      {
        type: "click",
        domID: "link_declaration",
        color: "#EADF5A",
      },

      {
        type: "hover",
        domID: "link_main_video",
        color: "#EADF5A",
      },
      {
        type: "click",
        domID: "link_main_video",
        color: "#EADF5A",
      },

      {
        type: "hover",
        domID: "link_project_a",
        color: "#F2BBBB",
      },
      {
        type: "click",
        domID: "link_project_a",
      },

      {
        type: "hover",
        domID: "link_project_b",
        color: "#F2BBBB",
      },
      {
        type: "click",
        domID: "link_project_b",
      },

      {
        type: "hover",
        domID: "link_project_c",
        color: "#CABCD6",
      },
      {
        type: "click",
        domID: "link_project_c",
      },

      {
        type: "hover",
        domID: "link_project_d",
        color: "#BAD5A9",
      },
      {
        type: "click",
        domID: "link_project_d",
      },

      {
        type: "hover",
        domID: "link_blog",
        color: "#F8F2E4",
      },
      {
        type: "click",
        domID: "link_blog",
      },
      {
        type: "hover",
        domID: "link_other",
        color: "#DDD",
      },
      {
        type: "click",
        domID: "link_other",
      },
    ],
  },
];
