import React from "react";

import RouterHOCInteractionBgCanvas from "./RouterHOCInteractionBgCanvas.js";
import routerConfig from "./settings/router-canvas.conf.js";

export default (props) => {
  return (
    <RouterHOCInteractionBgCanvas {...props} routerConfig={routerConfig} />
  );
};
