// import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"

class InViewObserver {

   constructor() {

      var hasClass = (function(){
         var div = document.createElement("div");
         if ( "classList" in div && typeof div.classList.contains === "function" ) {
            return function(elem, className){
               return elem.classList.contains(className);
            };
         } else {
            return function(elem, className) {
               var classes = elem.className.split(/\s+/);
               for (var i= 0 ; i < classes.length ; i ++) {
                  if ( classes[i] === className ) {
                     return true;
                  }
               }
               return false ;
            };
         }
      })();

      const section = document.querySelectorAll('.page_header, .page_content, .page_footer, .context, .context_s, .context_list, .figure, .figcaption, img, .video, .section_link_btn, .tag_wrap, .dialog_item, .persona_item, .enter_cover, .content_group, .post_item');

      var observer = new IntersectionObserver(callback, options);

      var options = {
         root: document.querySelector('#___gatsby'),
         rootMargin: '0px 0px -200px 0px',
         // threshold: 1.0
      }
      
      function callback (entries, observer) {
         // console.log(observer);
         entries.forEach(entry => {
            // console.log(entry);
            
            if (entry.isIntersecting) {
               if ( !hasClass(entry.target, 'in') ) {
                  entry.target.classList.add('in');
                  // console.log(entry.target.className + ' - ' + entry.intersectionRatio);
               }
            } else {
               // entry.target.classList.remove('in');
            }
         });
      }
      
      section.forEach(section_ele => {
         observer.observe(section_ele);
      });



      const section_img = document.querySelectorAll('[data-src]');

      var observer_img = new IntersectionObserver(callback_img, options_img);

      const options_img = {
         // root: document.body,
         rootMargin: '0px 0px 50% 0px',
         // threshold: [0, 0.5, 1]
      }
      
      function callback_img (entries, observer_img) {
         // console.log(observer);
         entries.forEach(entry => {
            // console.log(entry);
            if ( entry.isIntersecting ) {
               if ( entry.target.hasAttribute('data-src') ) {
                  // entry.target.classList.add('in');
                  entry.target.src = entry.target.dataset.src;
                  entry.target.removeAttribute('data-src');
                  // console.log(entry.target + ' - ' + entry.intersectionRatio);
                  // observer_img.unobserve(entry.target);
               }
            }
         });
      }
      
      section_img.forEach(section_img_ele => {
         observer_img.observe(section_img_ele);
      });
      
   }


}


export default InViewObserver



  