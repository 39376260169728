import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import sty from "./IndexSPA.module.scss";

import routerConfig from "./route.conf.js";

import TransitionMatchHOC from "../../components/TransitionMatchHOC";
import ContextProvider from "../../hooks/useContextSPATransitionLock.js";
import InteractionBgCanvas from "../../components/InteractionBgCanvas/index.js";
import Layout from "../../components/layout.js";

var og_img = 'https://online.home-to-all.com' + require('../../images/422socialimage.jpg');
var ios_icon = require('../../images/ios_icon.png');
var ms_icon = require('../../images/ms_icon.png');


const IndexSPA = ({ children }) => {
  const [isMount, setIsMount] = useState(false);
  const [isReadyToPreFetch, setIsReadyToPreFetch] = useState(false);

  useEffect(() => {
    setIsMount(true);
    return () => {};
  }, []);

  return (
    <>
    <ContextProvider>
      <Layout>
        {routerConfig.map(({ path, Component }, index) => (
          <TransitionMatchHOC key={index + path} path={path}>
            <Component />
          </TransitionMatchHOC>
        ))}
        <InteractionBgCanvas />
      </Layout>
    </ContextProvider>
    </>
  );
};

IndexSPA.propTypes = {};

export default IndexSPA;
